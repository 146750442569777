<template>
  <Page :loading="loading">
    <Card content-class="c-table">
      <b-tabs
        v-if="!loading"
        class="mb-0 paddingless-left"
      >
        <b-tab-item
          v-for="site in page.sites"
          :key="'tab' + site.id"
          :value="`${site.id}`"
        >
          <template slot="header">
            <img
              :src="'/img/sites/' + site.id + '.svg'"
              width="20"
              height="20"
              alt
              class="mr-2"
            >
            {{ site.label }}
          </template>
          <b-tabs
            :class="$device.mobile ? 'paddingless' : 'paddingless-right'"
            :vertical="!$device.mobile"
          >
            <b-tab-item
              v-for="tld in site.tlds"
              :key="`lang-${tld.id}-${tld.country.iso}`"
              header-class="mb-4"
            >
              <template slot="header">
                <img
                  :src="'/img/flags/' + tld.country.iso.toUpperCase() + '.svg'"
                  width="18"
                  height="18"
                  class="mr-2"
                >
                {{ tld.country.label }}
              </template>
              <ValidationObserver
                ref="observer"
                v-slot="{ handleSubmit }"
                slim
              >
                <form
                  method="post"
                  autocomplete="off"
                  class="columns is-multiline is-mobile ml-2"
                  @submit.prevent="handleSubmit(() => saveTrans(tld, site.id))"
                >
                  <div class="column is-6-tablet is-12-mobile">
                    <b-input-validation
                      v-model="tld.url"
                      :vid="'url_' + site.id + tld.language.iso"
                      :placeholder="placeholders.page.url"
                      :label="$t('url')"
                      :disabled="
                        disableInputs ||
                          !$can('update', 'settings.pages.routes')
                      "
                    />
                    <!-- rules="required" -->
                  </div>

                  <div class="column is-6-tablet is-12-mobile">
                    <b-input-validation
                      v-model="tld.meta_title"
                      :placeholder="placeholders.page.meta_title"
                      :label="`${$t('meta_title')}${
                        page.static ? `` : ` (${$t('default_value')})`
                      }`"
                      :rules="page.static ? `required` : ``"
                      :vid="'meta_title_' + site.id + tld.language.iso"
                      :disabled="disableInputs"
                      maxlength="90"
                      counter="90"
                    />
                  </div>

                  <div class="column is-6-tablet is-12">
                    <b-input-validation
                      v-model="tld.h1"
                      :placeholder="placeholders.page.h1"
                      :label="`${$t('h1')}${
                        page.static ? `` : ` (${$t('default_value')})`
                      }`"
                      :rules="page.static ? `required` : ``"
                      :vid="'h1' + site.id + tld.language.iso"
                      :disabled="disableInputs"
                      maxlength="80"
                      counter="80"
                    />
                  </div>

                  <div class="column is-12">
                    <b-input-validation
                      v-model="tld.meta_description"
                      :placeholder="placeholders.page.meta_description"
                      :label="`${$t('meta_description')}${
                        page.static ? `` : ` (${$t('default_value')})`
                      }`"
                      :rules="page.static ? `required` : ``"
                      :vid="'meta_description' + site.id + tld.language.iso"
                      :disabled="disableInputs"
                      maxlength="300"
                      counter="300"
                      :suffix="true"
                    >
                      <b-dropdown
                        slot="suffix"
                        position="is-bottom-left"
                        aria-role="list"
                      >
                        <template #trigger="{ active }">
                          <b-button
                            :label="$tc('variable', 2)"
                            :icon-right="active ? 'chevron-up' : 'chevron-down'"
                          />
                        </template>

                        <b-dropdown-item
                          v-for="v in copyVariables.list"
                          :key="v.label"
                          aria-role="listitem"
                          @click="$root.copytoClipboard(v.value)"
                        >
                          {{ v.label }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-input-validation>
                  </div>

                  <!-- <div class="column is-12"> -->
                  <!-- <b-input-validation
                      v-model="tld.content"
                      :placeholder="placeholders.page.content"
                      :label="
                        `${$t('content')}${
                          page.static ? `` : ` (${$t('optional')})`
                        }`
                      "
                      :rules="page.static ? 'required' : ''"
                      :vid="'content' + site.id + tld.language.iso"
                      :disabled="disableInputs"
                      input-type="textarea"
                    />
                  </div> -->
                  <ValidationProvider
                    v-slot="{ errors }"
                    tag="div"
                    class="column is-12"
                    :vid="'content' + site.id + tld.language.iso"
                    :name="$t('content')"
                    :rules="page.static ? `required` : ``"
                    mode="eager"
                  >
                    <b-field
                      :label="`${$t('content')}${
                        page.static ? `` : ` (${$t('default_value')})`
                      }`"
                      :disabled="disableInputs"
                      :message="errors"
                      :type="{
                        'is-default': disableInputs,
                        'is-danger': errors[0],
                      }"
                    >
                      <froala
                        v-if="!disableInputs"
                        :ref="`editor_${site.id}_${tld.id}`"
                        v-model="tld.content"
                        class="editor"
                        :config="editorConfig"
                      />

                      <froalaView
                        v-else
                        v-model="tld.content"
                      />
                    </b-field>
                  </ValidationProvider>

                  <div class="column is-12">
                    <b-button
                      type="is-primary"
                      :disabled="save_trans_loading || disableInputs"
                      :loading="save_trans_loading"
                      tag="input"
                      native-type="submit"
                      :value="$t('save')"
                    >
                      {{ $t("save") }}
                    </b-button>
                  </div>
                </form>
              </ValidationObserver>
            </b-tab-item>
          </b-tabs>
        </b-tab-item>
      </b-tabs>

      <b-skeleton
        v-else
        width="100%"
        height="300px"
        animated
      />
    </Card>
  </Page>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  data() {
    return {
      loading: true,
      disableInputs: true,
      save_trans_loading: false,
      page: {},
      editorConfig: {
        key: process.env.VUE_APP_FROALA_KEY,
        attribution: false,
        zIndex: 1,
        entities: "",
        pastePlain: true,
        shortcutsEnabled: ["bold", "italic", "underline", "undo", "redo"],
        pluginsEnabled: [
          "align",
          "charCounter",
          "codeBeautifier",
          "codeView",
          "colors",
          "draggable",
          "embedly",
          "emoticons",
          "file",
          "fontAwesome",
          "fontSize",
          "fullscreen",
          "inlineStyle",
          "inlineClass",
          "lineBreaker",
          "lineHeight",
          "link",
          "lists",
          "paragraphFormat",
          "paragraphStyle",
          "quickInsert",
          "entities",
          "quote",
          "save",
          "table",
          "url",
          "wordPaste",
        ],

        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "clearFormatting",
            ],
            buttonsVisible: 2,
          },
          moreParagraph: {
            buttons: [
              "alignLeft",
              "alignCenter",
              "formatOLSimple",
              "alignRight",
              "alignJustify",
              "formatOL",
              "formatUL",
              "paragraphFormat",
              "outdent",
              "indent",
              "quote",
              "specialCharacters",
              "insertHR",
              "emoticons",
            ],
          },
          moreRich: {
            buttons: ["insertLink", "insertTable"],
            buttonsVisible: 2,
          },
          moreMisc: {
            buttons: [
              "undo",
              "redo",
              "fullscreen",
              "selectAll",
              "html",
              "help",
            ],
            align: "right",
            buttonsVisible: 2,
          },
        },

        events: {
          "html.get": function (html) {
            return html.replace(/id="isPasted"/g, "");
          },
        },
      },
    };
  },
  created() {
    this.editorConfig.placeholderText = this.placeholders.page.content;
    this.updateTitle(this.$i18n.tc("page", 1));
    if (this.$can("update", "settings.pages.translations"))
      this.disableInputs = false;
    if (
      this.$can("read", "settings.pages.translations") ||
      this.$can("update", "settings.pages.translations")
    )
      this.getPage();
  },
  methods: {
    getPage() {
      this.loading = true;

      this.$axios
        .get(`pages/${this.$route.params.page_id}/trans`)
        .then((res) => {
          this.page = res.data;
          this.updateTitle(
            `${this.$i18n.tc("page", 1)}: ${
              res.data.prefix ? res.data.prefix + "/" : ""
            }${res.data.route}`
          );
        })
        .catch((e) => this.clientError(e))
        .finally(() => {
          this.loading = false;
        });
    },
    saveTrans(tld, site_id) {
      this.save_trans_loading = true;

      // get formatted HTML if code view is active
      var editor = this.$refs[`editor_${site_id}_${tld.id}`][0].$el["data-froala.editor"];
      if (editor.codeView.isActive()) {
        editor.html.set(editor.codeView.get());
        editor.events.trigger("contentChanged", [], true);
      }

      let data = {
        tld_id: tld.id,
        lang_iso: tld.language.iso,
        trans_id: tld.trans_id,
        site_id: site_id,
        content: tld.content,
        h1: tld.h1,
        meta_description: tld.meta_description,
        meta_title: tld.meta_title,
        url: tld.url,
      };

      this.$axios
        .put(`pages/${this.$route.params.page_id}/trans`, data)
        .then(() => {
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch((e) => this.clientError(e))
        .finally(() => {
          this.save_trans_loading = false;
        });
    },
  },
};
</script>
