var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"loading":_vm.loading}},[_c('Card',{attrs:{"content-class":"c-table"}},[(!_vm.loading)?_c('b-tabs',{staticClass:"mb-0 paddingless-left"},_vm._l((_vm.page.sites),function(site){return _c('b-tab-item',{key:'tab' + site.id,attrs:{"value":("" + (site.id))}},[_c('template',{slot:"header"},[_c('img',{staticClass:"mr-2",attrs:{"src":'/img/sites/' + site.id + '.svg',"width":"20","height":"20","alt":""}}),_vm._v(" "+_vm._s(site.label)+" ")]),_c('b-tabs',{class:_vm.$device.mobile ? 'paddingless' : 'paddingless-right',attrs:{"vertical":!_vm.$device.mobile}},_vm._l((site.tlds),function(tld){return _c('b-tab-item',{key:("lang-" + (tld.id) + "-" + (tld.country.iso)),attrs:{"header-class":"mb-4"}},[_c('template',{slot:"header"},[_c('img',{staticClass:"mr-2",attrs:{"src":'/img/flags/' + tld.country.iso.toUpperCase() + '.svg',"width":"18","height":"18"}}),_vm._v(" "+_vm._s(tld.country.label)+" ")]),_c('ValidationObserver',{ref:"observer",refInFor:true,attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"columns is-multiline is-mobile ml-2",attrs:{"method":"post","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.saveTrans(tld, site.id); })}}},[_c('div',{staticClass:"column is-6-tablet is-12-mobile"},[_c('b-input-validation',{attrs:{"vid":'url_' + site.id + tld.language.iso,"placeholder":_vm.placeholders.page.url,"label":_vm.$t('url'),"disabled":_vm.disableInputs ||
                        !_vm.$can('update', 'settings.pages.routes')},model:{value:(tld.url),callback:function ($$v) {_vm.$set(tld, "url", $$v)},expression:"tld.url"}})],1),_c('div',{staticClass:"column is-6-tablet is-12-mobile"},[_c('b-input-validation',{attrs:{"placeholder":_vm.placeholders.page.meta_title,"label":("" + (_vm.$t('meta_title')) + (_vm.page.static ? "" : (" (" + (_vm.$t('default_value')) + ")"))),"rules":_vm.page.static ? "required" : "","vid":'meta_title_' + site.id + tld.language.iso,"disabled":_vm.disableInputs,"maxlength":"90","counter":"90"},model:{value:(tld.meta_title),callback:function ($$v) {_vm.$set(tld, "meta_title", $$v)},expression:"tld.meta_title"}})],1),_c('div',{staticClass:"column is-6-tablet is-12"},[_c('b-input-validation',{attrs:{"placeholder":_vm.placeholders.page.h1,"label":("" + (_vm.$t('h1')) + (_vm.page.static ? "" : (" (" + (_vm.$t('default_value')) + ")"))),"rules":_vm.page.static ? "required" : "","vid":'h1' + site.id + tld.language.iso,"disabled":_vm.disableInputs,"maxlength":"80","counter":"80"},model:{value:(tld.h1),callback:function ($$v) {_vm.$set(tld, "h1", $$v)},expression:"tld.h1"}})],1),_c('div',{staticClass:"column is-12"},[_c('b-input-validation',{attrs:{"placeholder":_vm.placeholders.page.meta_description,"label":("" + (_vm.$t('meta_description')) + (_vm.page.static ? "" : (" (" + (_vm.$t('default_value')) + ")"))),"rules":_vm.page.static ? "required" : "","vid":'meta_description' + site.id + tld.language.iso,"disabled":_vm.disableInputs,"maxlength":"300","counter":"300","suffix":true},model:{value:(tld.meta_description),callback:function ($$v) {_vm.$set(tld, "meta_description", $$v)},expression:"tld.meta_description"}},[_c('b-dropdown',{attrs:{"slot":"suffix","position":"is-bottom-left","aria-role":"list"},slot:"suffix",scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                    var active = ref.active;
return [_c('b-button',{attrs:{"label":_vm.$tc('variable', 2),"icon-right":active ? 'chevron-up' : 'chevron-down'}})]}}],null,true)},_vm._l((_vm.copyVariables.list),function(v){return _c('b-dropdown-item',{key:v.label,attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$root.copytoClipboard(v.value)}}},[_vm._v(" "+_vm._s(v.label)+" ")])}),1)],1)],1),_c('ValidationProvider',{staticClass:"column is-12",attrs:{"tag":"div","vid":'content' + site.id + tld.language.iso,"name":_vm.$t('content'),"rules":_vm.page.static ? "required" : "","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-field',{attrs:{"label":("" + (_vm.$t('content')) + (_vm.page.static ? "" : (" (" + (_vm.$t('default_value')) + ")"))),"disabled":_vm.disableInputs,"message":errors,"type":{
                      'is-default': _vm.disableInputs,
                      'is-danger': errors[0],
                    }}},[(!_vm.disableInputs)?_c('froala',{ref:("editor_" + (site.id) + "_" + (tld.id)),refInFor:true,staticClass:"editor",attrs:{"config":_vm.editorConfig},model:{value:(tld.content),callback:function ($$v) {_vm.$set(tld, "content", $$v)},expression:"tld.content"}}):_c('froalaView',{model:{value:(tld.content),callback:function ($$v) {_vm.$set(tld, "content", $$v)},expression:"tld.content"}})],1)]}}],null,true)}),_c('div',{staticClass:"column is-12"},[_c('b-button',{attrs:{"type":"is-primary","disabled":_vm.save_trans_loading || _vm.disableInputs,"loading":_vm.save_trans_loading,"tag":"input","native-type":"submit","value":_vm.$t('save')}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)]}}],null,true)})],2)}),1)],2)}),1):_c('b-skeleton',{attrs:{"width":"100%","height":"300px","animated":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }